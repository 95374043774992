// import logoForID from '@/assets/imgs/logo-id.png'
import i18n from '@/i18n/i18n.js'

export const getFormV2Columns = () => {
  return {
    'schema': {
      'type': 'object',
      'title': 'create form',
      'description': '',
      'properties': {},
      'required': []
    },
    'columns': [
      {
        'name': '',
        'schema': {
          'type': 'object',
          'description': '自定义对象组',
          'properties': {},
          'required': []
        },
        'fieldJson': {
          'noteStyle': {},
          'tipTitleStyle': {},
          'tipContentStyle': {},
          'extraConfig': {},
          'required': true,
          'show': true,
          'tipShowType': 'auto',
          'isCustom': true,
          'ocrSwitch': []
        },
        'renderType': 'Object',
        'columns': [
          {
            'name': i18n.t('ebClaim.IdentityNumber'),
            'nameLocal': i18n.t('ebClaim.IdentityNumber'),
            'key': 'identityNo',
            'schema': {
              'type': 'string',
              'maxLength': 16,
              'minLength': 9,
              'description': '证件号码'
            },
            'renderType': 'Input',
            'renderKey': '1728713856247_779',
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'suffix': '',
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'extendDisplayCfg': [],
              'requiredMessage': i18n.t('ebClaim.IdentityNumberIsRequired'),
              'requiredMessageLocal': i18n.t('ebClaim.IdentityNumberIsRequired')
            }
          },
          {
            'name': i18n.t('ebClaim.EmployeeName'),
            'nameLocal': i18n.t('ebClaim.EmployeeName'),
            'key': 'employeeName',
            'schema': {
              'type': 'string',
              // 'maxLength': 16,
              // 'minLength': 9,
              'description': '证件号码'
            },
            'renderType': 'Input',
            'renderKey': '1728713856247_779',
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'suffix': '',
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'extendDisplayCfg': [],
              'requiredMessage': i18n.t('ebClaim.EmployeeNameIsRequired'),
              'requiredMessageLocal': i18n.t('ebClaim.EmployeeNameIsRequired')
            }
          },
          {
            'name': i18n.t('ebClaim.PhoneNumber'),
            'nameLocal': i18n.t('ebClaim.PhoneNumber'),
            'schema': {
              'type': 'integer',
              'description': '自定义多行文本输入框'
            },
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': true,
              'tipShowType': 'auto',
              'defaultValueSource': 1,
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'isCustom': true,
              'extendDisplayCfg': [],
              'prefixType': '2',
              'prefix': '84',
              'displayRely': false,
              'prefixPath': [
                'claimantInfo',
                'reporterMobileIdd'
              ],
              'prefixBuilt': [
                'country'
              ],
              'requiredMessage': i18n.t('ebClaim.PhoneNumberIsRequired'),
              'requiredMessageLocal': i18n.t('ebClaim.PhoneNumberIsRequired')
            },
            'renderType': 'Numeric',
            'key': 'phone',
            'renderKey': '1726303196532_43870'
          },
          {
            'name': 'prefix',
            'schema': {
              'type': 'string',
              'description': '国家区号'
            },
            'fieldJson': {
              'noteStyle': {},
              'tipTitleStyle': {},
              'tipContentStyle': {},
              'extraConfig': {},
              'required': true,
              'show': false,
              'tipShowType': 'auto',
              'suffix': '',
              'defaultValueSource': '1',
              'displayRelyGather': [],
              'defaultValueOriginParams': [],
              'defaultValueOriginCfg': {
                'method': 'POST'
              },
              'isCustom': true,
              'extendDisplayCfg': [],
              'isHideInDetails': true,
              'defaultValue': '84'
            },
            'renderType': 'Input',
            'key': 'prefix',
            'renderKey': '1723517782275_4772',
            'nameLocal': 'Reporter Mobile IDD'
          }
        ],
        'key': 'shareDatas',
        'renderKey': '1725359475465_46497',
        'aliasName': ''
      }
    ],
    'fieldJson': {
      'version': '2.7.0',
      'hasClause': false,
      'clauseContent': '',
      'clauseContentLocal': '',
      'styleVersion': 'V2',
      'hasTerms': false,
      'termsLs': []
    },
    'config': {
      'labelWidth': 200
    }
  }
}
